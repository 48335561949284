















































































































































































































































































































.listQuan {
  .van-checkbox__icon--disabled {
    .checkboxIco {
      background: rgba(111, 121, 137, 0.1);
	  border: 1px solid rgba(111, 121, 137, 0.1);
    }
  }
  .checkboxIco {
    width: 0.33rem;
    height: 0.33rem;
    border: 1px solid #8e8f91;
    display: inline-block;
    border-radius: 100px;
	background: #FFFFFF;
	border: 1px solid #EFBA0D;
  }
  .checkboxIco.on {
    background: #EFBA0D;
    border: 1px solid #EFBA0D;
	position: relative;
  }
  .checkboxIco.on:before,
  .checkboxIco.on::after {
  	content: '';
  	height: 9px;
  	width: 1.5px;
  	border-radius: 10px;
  	display: block;
  	background: #FFFFFF;
  	position: absolute;
  	top: 3.5px;
  	left: 8.5px;
  	transform: rotate(45deg);
  	-ms-transform: rotate(45deg);
  }
  .checkboxIco.on::before {
  	height: 5px;
  	transform: rotate(-45deg);
  	-ms-transform: rotate(-45deg);
  	position: absolute;
  	top: 6px;
  	left: 3.5px;
  }
  .setup {
    padding: 0 0.2rem;
    width: 100%;
    overflow: hidden;
    height: 0.47rem;
    line-height: 0.46rem;
    background: #fdf8ec;
    position: fixed;
    left: 0;
    bottom: 60px;
    font-size: 0.24rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: rgb(4, 93, 255);
    span {
      float: right;
    }
  }

  .listButton {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    .buttonSetup {
      border: 1px solid #0757f5;
      color: #fff;
      border-radius: 4px;
      background: #0757f5;
      font-size: 0.2rem;
      width: 0.9rem;
      height: 0.38rem;
      margin-bottom: 0.1rem;
      margin-right: 0.1rem;
    }
    .buttonStart {
      border-radius: 4px;
      background: #fff;
      font-size: 0.2rem;
      width: 0.9rem;
      height: 0.38rem;
    }
    .buttonStart,
    .buttonNone,
    .buttonStop {
      border: 1px solid rgb(4, 93, 255);
      color: rgb(4, 93, 255);
      border-radius: 4px;
      background: #fff;
      font-size: 0.2rem;
      width: 0.8rem;
      height: 0.38rem;
    }
    .buttonStop {
      border: 1px solid rgb(217, 105, 105);
      color: rgb(217, 105, 105);
    }

    .van-button--small {
      width: 0.78rem;
      height: 0.42rem;
      line-height: 0.4rem;
      text-align: center;
      margin-right: 0.27rem;
      border: none;
      padding: 0;
      min-width: 0.77rem;
      border-radius: 5px;

      .van-button__text {
        color: #fff;
        font-size: 0.23rem;
        font-family: PingFang SC;
        font-weight: 500;
      }
    }

    .van-button--small:last-child {
      margin-right: 0;
    }
  }
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;