.change {
  display: flex;
  width: 100%;
  font-size: 0.2rem;
  margin-top: 0.16rem;
}
.itemChange {
  width: 1.4rem;
  color: #888;
}
.itemChange .v {
  margin-left: 3px;
  font-weight: 500;
  font-size: 0.2rem;
  color: #333;
  font-family: Bahnschrift;
}
.content {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.title {
  display: inline;
  border-radius: 5px;
  margin-left: 0.3rem;
  color: #fb8b0d;
  font-size: 0.26rem;
}
.spe {
  display: inline-block;
  height: 0.2rem;
  border-right: 1px solid #e7e7e7;
}
.quantification .searchBox {
  margin: 0 0.3rem 0.4rem;
}
.quantification .searchRelative {
  position: relative;
}
.quantification .searchRelative .searchButton {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 0.31rem;
  font-weight: 600;
  color: #373737;
  width: 1.6rem;
  height: 0.9rem;
  line-height: 0.9rem;
  text-align: center;
  background: #f0f0f0;
  border-radius: 0.5rem;
}
.quantification .filterBox {
  margin-top: 0.2rem;
  padding: 0.2rem 0.15rem;
  background: #ffffff;
  box-shadow: 0px 2px 18px 0px rgba(202, 202, 202, 0.3);
  padding: 0.2rem 0.57rem 0.2rem 0.61rem;
  border-top-left-radius: 0.32rem;
  border-top-right-radius: 0.32rem;
}
.quantification .menuLine {
  padding: 0.2rem 0;
  position: relative;
  display: flex;
}
.quantification .menuLine .menuName {
  font-size: 0.24rem;
  color: #373737;
  display: inline-block;
  margin-top: 0.03rem;
}
.quantification .menuLine .menuItem {
  font-family: PingFangSC-Regular;
  margin-right: 30.5px;
  font-size: 0.28rem;
  color: #8f9eab;
  position: relative;
  text-transform: capitalize;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.quantification .menuLine .menuBlock {
  font-size: 0.24rem;
  border-radius: 4px;
  padding: 3px 6px;
  width: auto !important;
  background-color: #f5f5f5;
  margin-right: 0 !important;
}
.quantification .menuLine .menuItem:last-child {
  margin-right: 0;
}
.quantification .menuLine .menuItem.on {
  font-family: PingFangSC-Medium;
  font-weight: 600;
  font-size: 0.28rem;
  color: #333333;
}
.quantification .menuLine .menuItem1 {
  font-family: PingFangSC-Regular;
  margin-right: 0.42rem;
  font-size: 0.28rem;
  color: #8f9eab;
  position: relative;
  text-transform: capitalize;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.quantification .menuLine .menuItem1:last-child {
  margin-right: 0;
}
.quantification .menuLine .menuItem1.on {
  font-family: PingFangSC-Medium;
  font-weight: 600;
  font-size: 0.28rem;
  color: #333333;
}
.quantification .menuLine .menuBlock.on {
  font-family: PingFangSC-Medium;
  color: #333333;
  font-size: 0.24rem;
  font-weight: bold;
}
.quantification .menuLine .dropdownBtn {
  color: #3893fb;
  font-size: 0.32rem;
}
.quantification .listQuan {
  padding-bottom: 1.2rem;
  margin: 0 0.245rem 0;
}
.quantification .listQuan li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 1.39rem;
  border-bottom: 1px solid #e4e7ed;
}
.quantification .listQuan li .name {
  font-size: 0.28rem;
  font-weight: bold;
  color: #181818;
}
.quantification .listQuan li .checkbox {
  display: inline-block;
  vertical-align: -0.05rem;
  margin-right: 0.16rem;
}
.list {
  padding-bottom: 1.5rem;
}
.tan {
  margin-top: 0.4rem;
}
.tan .tan_header {
  margin-left: 0.3rem;
  display: flex;
  align-items: center;
}
.tan .tan_header .tan_header_biaozi {
  width: 0.1rem;
  height: 0.1rem;
  border-radius: 50%;
  background-color: #373737;
}
.tan .tan_header .tan_header_txt {
  font-family: PingFang SC;
  margin: 0 auto;
  color: #333333;
  font-size: 0.24rem;
}
.tan .tan_content {
  padding: 0 0.8rem;
  display: flex;
  justify-content: space-between;
  margin-top: 0.48rem;
}
.tan .tan_content .tan_item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tan .tan_content .tan_item .img0 {
  width: 0.55rem;
  height: 0.44rem;
  text-align: center;
  line-height: 0.44rem;
}
.tan .tan_content .tan_item .img0 img {
  width: 100%;
  height: 100%;
}
.tan .tan_content .tan_item .img {
  width: 0.44rem;
  height: 0.44rem;
  text-align: center;
  line-height: 0.44rem;
}
.tan .tan_content .tan_item .img img {
  width: 100%;
  height: 100%;
}
.tan .tan_content .tan_item .tan_item_txt {
  margin-top: 0.2rem;
  width: 100%;
  color: #333333;
  font-size: 0.24rem;
  text-align: center;
}
.setResultWin {
  width: 50%;
  padding: 0.2rem;
  border: 1px solid #eee;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.03);
  border-radius: 3px;
}
.setResultWin div {
  padding: 0.1rem;
}
.header {
  background-color: #ffffff;
  border-radius: 0.15rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.3rem;
}
.title {
  color: #6f7989;
  font-size: 0.28rem;
  font-family: PingFang SC;
}
.right1 {
  color: #6f7989;
  font-size: 0.28rem;
  text-align: right;
}
.right2 {
  margin-left: 0.6rem;
  color: #6f7989;
  font-size: 0.28rem;
  text-align: center;
}
.zichan {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  left: 0;
  bottom: 1.13rem;
  bottom: calc(env(safe-area-inset-bottom) + 1.13rem);
  border-bottom: 0.01rem solid #F6F6F6;
  height: 0.72rem;
  width: 100%;
  background-color: #FFFFFF;
  padding: 0 0.1rem;
  padding: 0 0.4rem;
}
.zichan .z_left {
  font-family: PingFangSC-Regular;
  font-size: 0.24rem;
  color: #6F7A8B;
}
.zichan .z_right {
  display: flex;
  align-content: center;
}
.zichan .z_right .txt {
  font-family: PingFangSC-Regular;
  font-size: 0.24rem;
  color: #EFBA0D;
}
.zichan .z_right .img {
  padding-left: 0.2rem;
}
.zichan .z_right .img img {
  width: 0.24rem;
  height: 0.24rem;
}
.tab_bottom {
  display: flex;
  justify-content: space-between;
  position: fixed;
  left: 0;
  bottom: 0;
  height: 1.14rem;
  width: 100%;
  background-color: #ffffff;
  border-top: 0.005rem solid #dedede;
}
.tab_bottom .tab_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.14rem 0.8rem;
}
.tab_bottom .tab_item .logo {
  width: 0.4rem;
  height: 0.4rem;
}
.tab_bottom .tab_item .logo img {
  width: 100%;
  height: 100%;
}
.tab_bottom .tab_item .logo1 {
  width: 0.32rem;
  height: 0.4rem;
}
.tab_bottom .tab_item .logo1 img {
  width: 100%;
  height: 100%;
}
.tab_bottom .tab_item .txt {
  color: #acb5bc;
  margin-top: 0.04rem;
  font-size: 0.24rem;
  transform: scale(0.916);
}
.tab_bottom .tab_item .active {
  color: #efba0d;
  font-family: PingFangSC-Medium;
}
.menu_head {
  position: relative;
  z-index: 99;
  background-color: #FFFFFF;
}
.menu_head .menu_list {
  padding: 0.39rem 0.6rem 0.2rem 0.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.menu_head .menu_list .menu_item0 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 33.3%;
}
.menu_head .menu_list .menu_item0 .txt {
  color: #6F7989;
  font-size: 0.28rem;
  font-weight: bold;
}
.menu_head .menu_list .menu_item0 .txt.on {
  color: #333333;
}
.menu_head .menu_list .menu_item0 img {
  margin-left: 0.12rem;
  width: 0.16rem;
  height: 0.11rem;
}
.menu_head .menu_list .menu_item1 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33.3%;
}
.menu_head .menu_list .menu_item1 .txt {
  color: #6F7989;
  font-size: 0.28rem;
  font-weight: bold;
}
.menu_head .menu_list .menu_item1 .txt.on {
  color: #333333;
}
.menu_head .menu_list .menu_item1 img {
  margin-left: 0.12rem;
  width: 0.16rem;
  height: 0.11rem;
}
.menu_head .menu_list .menu_item2 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 33.3%;
}
.menu_head .menu_list .menu_item2 .txt {
  color: #6F7989;
  font-size: 0.28rem;
  font-weight: bold;
}
.menu_head .menu_list .menu_item2 .txt.on {
  color: #333333;
}
.menu_head .menu_list .menu_item2 img {
  margin-left: 0.12rem;
  width: 0.16rem;
  height: 0.11rem;
}
.menu_head .xiala_msg {
  position: absolute;
  left: 0;
  top: 1rem;
  z-index: 99;
  background-color: #FFFFFF;
  width: 100%;
}
.menu_head .xiala_msg .xiala_list {
  padding: 0.2rem 0.24rem 0.2rem 0.24rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.menu_head .xiala_msg .xiala_list .xiala_item {
  width: 2.18rem;
  height: 0.72rem;
  background-color: #F7F7FA;
  font-size: 0.28rem;
  color: #6F7989;
  text-align: center;
  line-height: 0.72rem;
  margin-bottom: 0.2rem;
  border-radius: 0.08rem;
  margin-right: 0.24rem;
}
.menu_head .xiala_msg .xiala_list .xiala_item:nth-child(3) {
  margin-right: 0;
}
.menu_head .xiala_msg .xiala_list .xiala_item:nth-child(6) {
  margin-right: 0;
}
.menu_head .xiala_msg .xiala_list .xiala_item.on {
  background-color: #E7ECEF;
  color: #333333;
  font-weight: bold;
}
.menu_head .menu_zhuangtai {
  padding: 0 0.8rem;
  padding-top: 0.2rem;
  padding-bottom: 0.4rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.menu_head .menu_zhuangtai .tai_item {
  background-color: #F7F7FA;
  color: #8F9EAB;
  font-size: 0.24rem;
  padding: 0.06rem 0.24rem;
}
.menu_head .menu_zhuangtai .tai_item.on {
  font-weight: bold;
  color: #333333;
}
.quanxuan {
  font-size: 0.24rem;
  color: #EFBA0D;
}
