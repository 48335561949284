













































































































































.asideLeft {
	position: fixed;
	z-index: 99;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	.asideBg {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		z-index: 1;
		background: rgba(4, 4, 15, 0.4);
	}
	.asideCon {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		z-index: 2;
		width: 6.15rem;
		// padding: .88rem .3rem .3rem;
		padding: 0 .3rem .3rem;
		background: #FFFFFF url(../assets/imgs/aside_bg.png) no-repeat center top;
		background-size: 100% auto;
		overflow-y: auto;
	}
	.backBtn {
		width: .6rem;
		height: .88rem;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
.userInfo {
	padding: .2rem .25rem;
	background: rgba(255, 255, 255, 0.16);
	border-radius: .12rem;
	display: flex;
	align-items: center;
	margin-bottom: .25rem;
	.avatar {
		width: 1.2rem;
		height: 1.2rem;
		margin-right: .25rem;
		.userHead {
			display: block;
			width: 100%;
			height: 100%;
			border-radius: 50%;
		}
		.level {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: -.2rem;
			img {
				width: .4rem;
				height: .4rem;
				display: block;
				margin-right: -.25rem;
				position: relative;
				z-index: 2;
			}
			span {
				color: #FFFFFF;
				font-size: .12rem;
				width: 1rem;
				height: .24rem;
				line-height: .24rem;
				padding-left: .3rem;
				background: linear-gradient(139deg, #BBDDFF 0%, #AFC2FF 100%);
				border: 1px solid #FFFFFF;
				border-radius: .16rem;
				display: block;
				position: relative;
				z-index: 1;
			}
		}
	}
	.nike {
		color: #373737;
		font-size: .36rem;
		line-height: .5rem;
		margin-bottom: .08rem;
	}
	.deadline {
		color: #666666;
		font-size: .24rem;
	}
}

.adPoster {
	height: 1.35rem;
	margin-bottom: .5rem;
	img {
		display: block;
		width: 100%;
		height: 100%;
	}
}
.logOutBtn {
	color: #FFFFFF;
	font-size: .32rem;
	height: .88rem;
	line-height: .88rem;
	text-align: center;
	background: #D1D1D6;
	border-radius: .12rem;
	margin-top: 1rem;
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;