.header {
  border-radius: 0.15rem;
  display: flex;
  align-items: center;
  padding: 0 0.2rem;
  margin-bottom: 0.1rem;
}
.header .kuai {
  width: 0.1rem;
  height: 0.26rem;
  background-color: #F8D33A;
  margin-right: 0.12rem;
}
.title {
  color: #333333;
  font-size: 0.32rem;
  font-family: PingFang SC;
  width: 50%;
}
.header_right {
  width: 50%;
  font-family: PingFang SC;
  color: #373737;
  font-size: 0.28rem;
  display: flex;
  justify-content: space-between;
}
.header_right .right1 {
  width: 40%;
  text-align: right;
}
.header_right .right2 {
  width: 65%;
  text-align: center;
}
.quantification .listQuan {
  background-color: #ffffff;
  border-top-left-radius: 0.32rem;
  border-top-right-radius: 0.32rem;
  margin-bottom: 0.2rem;
}
.quantification .listQuan:last-child {
  border-bottom: none;
}
.listQuan_1 {
  display: flex;
  justify-content: space-between;
  margin: 0 0.4rem;
  padding-top: 16px;
  align-items: center;
}
.listQuan_1 .listQuan_1_left {
  display: flex;
  align-items: center;
}
.listQuan_1 .listQuan_1_left .csTitle span {
  color: #90C4FF;
  font-size: 0.24rem;
  transform: scale(0.83);
  padding: 0 0.1rem;
  line-height: 0.36rem;
  background: #F1F7FF;
  border-radius: 0.04rem;
  display: inline-block;
  margin-left: 0.1rem;
}
.listQuan_1 .listQuan_1_left .csTitle span.gray {
  color: #9B9B9B;
  background: #F5F5F5;
}
.listQuan_1 .listQuan_1_left .csTitle span.gray1 {
  color: #F5465D;
  background: rgba(245, 70, 93, 0.09);
  border-radius: 0.04rem;
}
.listQuan_1 .listQuan_1_left .csTitle span.gray2 {
  color: #EFBA0D;
  background: #fffae8;
  border-radius: 0.04rem;
}
.listQuan_1 .listQuan_1_left .checkbox {
  margin-left: 0.15rem;
  display: flex;
  align-items: center;
}
.listQuan_1 .listQuan_1_left .content_top {
  margin-left: 0.2rem;
  display: flex;
  align-items: flex-end;
}
.listQuan_1 .listQuan_1_left .content_top .name {
  font-family: PingFangSC-Medium;
  font-size: 0.28rem;
  color: #333300;
  font-weight: 600;
}
.listQuan_1 .listQuan_1_left .content_top .name1 {
  font-family: PingFangSC-Regular;
  font-size: 0.24rem;
  color: #333300;
}
.listQuan_1 .listQuan_1_right {
  display: flex;
  width: 50%;
  justify-content: space-between;
  align-items: center;
  font-family: PingFangSC-Medium;
}
.listQuan_1 .listQuan_1_right .right2 {
  font-weight: bold;
  text-align: center;
  color: #333333;
  font-size: 0.28rem;
}
.listQuan_1 .listQuan_1_right .right1 {
  text-align: center;
  border-radius: 4px;
  font-size: 0.24rem;
  padding: 7px 6.5px;
  color: #fff;
}
.listQuan_1 .listQuan_1_right .green {
  border: 1px solid #32BF88;
  background-color: #32BF88;
}
.listQuan_1 .listQuan_1_right .red {
  background-color: #F5465D;
  border: 1px solid #F5465D;
}
.listQuan_2 {
  font-family: PingFangSC-Regular;
  margin: 0 0.4rem;
  padding: 0.3rem 0;
  border-bottom: 0.5px solid #EAECEF;
  justify-content: space-around;
  display: flex;
}
.listQuan_2 .listQuan_2_item {
  text-align: center;
}
.listQuan_2 .listQuan_2_item .label {
  font-size: 14px;
  color: #333333;
}
.listQuan_2 .listQuan_2_item .value {
  color: #333333;
  font-size: 14px;
}
.listQuan_2 .listQuan_2_item .green {
  color: #32BF88;
}
.listQuan_2 .listQuan_2_item .red {
  color: #F5465D;
}
.listQuan_2 .listQuan_2_name {
  color: #373737;
  font-size: 0.36rem;
  font-family: PingFang SC;
  display: flex;
  justify-content: space-between;
  height: 0.8rem;
  align-items: center;
}
.listQuan_2 .listQuan_2_value {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 0.8rem;
}
.listQuan_2 .listQuan_2_value .listQuan_2_value_left {
  display: flex;
  align-items: center;
}
.listQuan_2 .listQuan_2_value .listQuan_2_value_left .chicang {
  font-size: 0.36rem;
  font-weight: 550;
  margin-right: 0.1rem;
}
.listQuan_2 .listQuan_2_value .listQuan_2_value_left .bucang {
  font-size: 0.22rem;
  color: #373737;
}
.listQuan_2 .listQuan_2_value .listQuan_2_value_right {
  display: flex;
  align-items: center;
}
.listQuan_2 .listQuan_2_value .listQuan_2_value_right .fiyin {
  font-size: 0.36rem;
  font-weight: 550;
  margin-left: 0.1rem;
}
.listQuan_2 .listQuan_2_value .listQuan_2_value_right .fudu {
  font-size: 0.24rem;
}
.listQuan_2 .listQuan_2_value .listQuan_2_value_right .green {
  color: #07ad91;
}
.listQuan_2 .listQuan_2_value .listQuan_2_value_right .red {
  color: #cf4e65;
}
.listQuan_3 {
  padding: 0.14rem 0.25rem 0.08rem 0.15rem;
  display: flex;
  align-items: center;
}
.listQuan_3 .btn {
  text-align: center;
  flex: 1;
  height: 33.5px;
  line-height: 33.5px;
  font-size: 14px;
  color: #6F7989;
}
.listQuan_3 .red {
  color: #F5465D !important;
}
