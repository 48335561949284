





















































































































































































	// body {
	// 	// background-color: #F5F5F5;
	// }
    .billBox {
		// font-family: PingFangSC-Regular;
        .billNumBox{
            border-bottom: 0.2rem solid RGBA(246, 248, 249, 1);
            // padding: 0.2rem 0 0.5rem;
        }
        .billNum {
            text-align: center;
            padding: 0.4rem 0rem 0.6rem 0rem;


            p {
                font-size: 0.27rem;
                font-family: PingFang SC;
                font-weight: 500;
                color: #fff;
            }

            span {
                display: block;
                margin-top: 0.2rem;
                font-size: 0.36rem;
                font-family: PingFang SC;
                font-weight: bold;
                color: #fff;
            }
        }
		.cumulative{
			background: url(../../assets/imgs/bill/ljyl.png) no-repeat;
			background-size: 100% 100%;
		}
		.today{
			background: url(../../assets/imgs/bill/jryl.png) no-repeat;
			background-size: 100% 100%;
		}
        .billList {
            margin: 0 0.2rem;
			.top {
				// border-bottom: 0.01rem solid #D3D9DE;
				padding: 0.2rem 0;
				display: flex;
				align-items: center;
				.kuai {
					margin-right: 0.22rem;
					width: 5px;
					height: 0.26rem;
					background-color: #F8D33A;
				}
				.caption {
					font-size: 0.32rem;
					font-family: PingFang SC;
					font-weight: bold;
					color: #333333;
					// margin-bottom: .25rem;
				}
			}
            .billItem {
				background: #FFFFFF;
				border-radius: 8px;
				margin-bottom: .2rem;
                // padding-bottom: 0.26rem;
                // border-bottom: 1px solid #f5f5f5;

                .billTitle {
                    // margin-top: 0.29rem;
                    font-weight: 400;
                    color: #02172E;
					line-height: .4rem;
					padding: .25rem .25rem .15rem;
					border-bottom: 1px solid #EDEDED;
					display: flex;
					align-items: center;
					justify-content: space-between;
					.t_left {
						display: flex;
						align-items: center;
					}

                    i {
                        margin-right: 0.15rem;
                        font-size: 0.29rem;
                        vertical-align: 0.01rem;
                    }

                    span {
                        float: right;
                        font-size: 0.24rem;
                        font-family: Bahnschrift;
                        font-weight: 300;
                        color: #B5B4BA;
                        // margin-top: 0.1rem;
                    }
					
					.name {
						color: #333333;
						font-size: 0.28rem;
					}
					.name1 {
						color: #666666;
						font-size: 0.24rem;
					}
                }

                .billCent {
                    // margin-top: 0.3rem;
                    position: relative;
                    text-align: center;
					padding: 0.2rem 0 0.3rem 0;

                    p {
                        font-size: 0.24rem;
                        color: #666666;
                    }

                    span {
                        display: block;
                        font-size: 0.28rem;
                        // font-family: Bahnschrift;
                        // font-weight: 400;
                        color: #333333;
                        height: 0.3rem;
                    }

                    .billGreen {
                        font-size: 0.28rem;
                        // font-family: Bahnschrift;
                        // font-weight: 600;
                        color: #32BF88;
                    }

                    i {
                        width: 1.5px;
                        height: 0.6rem;
                        background: #F5F5F5;
                        position: absolute;
                        right: 0;
                        top: 0.4rem;
                    }
					&:last-child {
						padding-right: 0.22rem;
					}
                }
				.billCent1{
				    // margin-top: 0.3rem;
				    position: relative;
				    text-align: left;
					padding: 0.2rem 0 0.3rem 0;
					padding-left: 0.22rem;
				
				    p {
				        font-size: 0.24rem;
				        color: #666666;
				    }
				
				    span {
				        display: block;
				        font-size: 0.28rem;
				        // font-family: Bahnschrift;
				        // font-weight: 400;
				        color: #333333;
				        height: 0.3rem;
				    }
				
				    .billGreen {
				        font-size: 0.28rem;
				        // font-family: Bahnschrift;
				        // font-weight: 600;
				        color: #23A453;
				    }
				
				    i {
				        width: 1.5px;
				        height: 0.6rem;
				        background: #F5F5F5;
				        position: absolute;
				        right: 0;
				        top: 0.4rem;
				    }
				}
            }

            .billItem:last-child {
                border-bottom: none;
            }
        }
		
		
    }

// ----------20200410[start]---------
.communityInfo {
	margin: 0.2rem;
	// padding: .6rem .2rem 0;
	// height: 1.72rem;
	background: #FFFFFF;
	// box-shadow: 0 2px 18px 0px rgba(202, 202, 202, 0.3);
	border-radius: 0.16rem;
	// margin: 0 .3rem .5rem;
	position: relative;
	z-index: 2;
	.list {
		padding: 0.4rem 0;
		// margin: ;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.item {
			width: 50%;
			color: #666666;
			font-size: .24rem;
			// width: 3rem;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			.yen {
				color: #333333;
				font-size: 0.36rem;
				font-weight: bold;
				// font-family: Bahnschrift;
				margin-bottom: 0.08rem;
			}
			.i_right {
				margin-left: 0.32rem;
				img {
					width: 0.15rem;
					height: 0.26rem;
				}
			}
		}
		.split {
			width: 1px;
			height: .6rem;
			background: #E7E7E7;
		}
	}
}


.layoutBox {
	padding-top: 1.6rem;
	margin-top: -1.6rem;
	min-height: calc(100vh - 1.8rem);
	position: relative;
	z-index: 1;
}
// ----------20200410[end]---------

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;