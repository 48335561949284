.layoutBox {
  padding: 0.3rem;
  min-height: calc(100vh - 1.76rem);
  padding-top: 1.18rem;
}
.article {
  color: #666666;
  font-size: 0.24rem;
  line-height: 1.5;
}
.article h4 {
  color: #373737;
  font-size: 0.28rem;
  margin-bottom: 0.2rem;
}
.article h5 {
  color: #373737;
  font-size: 0.24rem;
  margin-bottom: 0.1rem;
}
.article p {
  margin-bottom: 0.1rem;
}
.article div {
  margin-bottom: 0.2rem;
}
