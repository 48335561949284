


































































.layoutBox {
	height: calc(100vh - 1.76rem);
	padding: 0.25rem .3rem;
}
.list-flex-box {
	display: flex;
	flex-wrap: wrap;
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;