





























































































































































// .whole {
// 	// background: #ffffff;
// }

.title {
	background: #f6f8f9;
	height: 2rem;
	padding: 0.3rem;
}
.titleList {
	font-weight: bold;
	margin: 0 0.3rem;
	.top {
		// border-bottom: 0.01rem solid #D3D9DE;
		padding: 0.2rem 0;
		display: flex;
		align-items: center;
		.kuai {
			margin-right: 0.22rem;
			width: 5px;
			height: 0.26rem;
			background-color: #f8d33a;
		}
		.caption {
			font-size: 0.32rem;
			font-family: PingFang SC;
			font-weight: bold;
			color: #333333;
			// margin-bottom: .25rem;
		}
	}
}
.billNum {
	text-align: center;
	padding: 0.4rem 0rem 0.6rem 0rem;

	p {
		font-size: 0.27rem;
		font-family: PingFang SC;
		font-weight: 500;
		color: #fff;
	}

	span {
		display: block;
		margin-top: 0.2rem;
		font-size: 0.36rem;
		font-family: PingFang SC;
		font-weight: bold;
		color: #fff;
	}
}

.cumulative {
	background: url(../../assets/imgs/bill/ljyl.png) no-repeat;
	background-size: 100% 100%;
}

.today {
	background: url(../../assets/imgs/bill/jryl.png) no-repeat;
	background-size: 100% 100%;
}



.bodyLiStyle {
	// margin: 0.2rem 0;
	color: #666666;
	font-size: 0.24rem;
	line-height: 0.35rem;
	&.right {
		width: 2.4rem;
	}
}
.content_list {
	margin: 0 0.2rem;
	padding: 0.1rem 0;
	background: #ffffff;
	border-radius: 0.16rem;
}
.bodyDivStyle {
	// border-radius: 8px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.2rem 0;
	margin: 0 0.2rem;
	// padding-bottom: 0.1rem;
	border-bottom: 1px solid #ededed;
	.list_left {
		margin-left: 0.2rem;
		.num {
			color: #32BF88;
			font-size: 0.28rem;
			font-weight: bold;
		}
		.txt {
			color: #666666;
			font-size: 0.24rem;
		}
	}
	.list_right {
		margin-right: 0.2rem;
		.txt {
			text-align: right;
			color: #666666;
			font-size: 0.24rem;
		}
	}
}
.bodyDivStyle:last-child {
	border-bottom: none;
}

.titleVanRowDivStyle {
	width: 100%;
	background: #ffffff;
}

// ----------20200410[start]---------
.communityInfo {
	margin: 0.2rem;
	// padding: .6rem .2rem 0;
	// height: 1.72rem;
	background: #ffffff;
	// box-shadow: 0 2px 18px 0px rgba(202, 202, 202, 0.3);
	border-radius: 0.16rem;
	// margin: 0 .3rem .5rem;
	position: relative;
	z-index: 2;
	.list {
		padding: 0.4rem 0;
		// margin: ;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.item {
			width: 50%;
			color: #666666;
			font-size: 0.24rem;
			// width: 3rem;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			.yen {
				color: #333333;
				font-size: 0.36rem;
				font-weight: bold;
				// font-family: Bahnschrift;
				margin-bottom: 0.08rem;
			}
			.i_right {
				margin-left: 0.32rem;
				img {
					width: 0.15rem;
					height: 0.26rem;
				}
			}
		}
		.split {
			width: 1px;
			height: 0.6rem;
			background: #e7e7e7;
		}
	}
}

.caption {
	font-size: 0.32rem;
	// font-family: PingFang SC;
	font-weight: bold;
	color: #373737;
	// margin-bottom: .25rem;
}
.layoutBox {
	// padding-top: 1.6rem;
	// margin-top: -1.6rem;
	position: relative;
	z-index: 1;
}
// ----------20200410[end]---------

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;