.loginButtonQ {
  width: 100%;
  border-radius: 0.1rem;
  height: 0.88rem;
  background: linear-gradient(90deg, #E0C3FC 0%, #8EC5FC 100%);
  line-height: 0.88rem;
  border: none;
  margin-top: 0.7rem;
  font-size: 0.32rem;
  color: #999999;
  font-weight: 600;
}
.loginButtonQ.disabled {
  background: #E9E9E9 !important;
}
.c-reg-box {
  overflow: hidden;
  background: #ffffff;
  font-size: 0.38rem;
  color: #ffffff;
}
.c-reg-box .login-logo {
  width: 100%;
  height: 3.6rem;
  margin-top: 1rem;
  position: relative;
}
.c-reg-box .login-logo .logo-shallde {
  width: 2rem;
  height: 2rem;
  box-shadow: 0rem 0.1rem 0.2rem #f3f0f0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -1rem;
  margin-top: -1rem;
}
.c-reg-box .login-logo .logo-shallde img {
  width: 1.4rem;
  height: 1.4rem;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -0.7rem;
  margin-top: -0.7rem;
}
.c-reg-box .c-reg-nav {
  width: 4.3rem;
  margin-left: 0.6rem;
  margin-top: 0.8rem;
  display: flex;
  box-sizing: border-box;
}
.c-reg-box .c-reg-nav > div {
  flex: 1;
  text-align: center;
}
.c-reg-box .c-reg-nav > div > a {
  color: #b1a9a9;
  display: block;
  cursor: pointer;
  padding: 0.1rem 0;
  position: relative;
}
.c-reg-box .c-reg-nav > div > a.active {
  color: #1e1010;
  font-weight: 600;
}
.c-reg-box .c-reg-nav > div > a.active:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 80%;
  transform: translateX(-50%);
}
.c-reg-box .c-reg-btn {
  display: block;
  border: none;
  width: 80%;
  height: 1rem;
  color: #b1a9a9;
  border-radius: 0.6rem;
  margin: 0.4rem auto;
  font-size: 0.32rem;
  background-color: #e0dcdc;
}
.c-reg-box .c-reg-btn2 {
  display: block;
  border: none;
  width: 80%;
  height: 1rem;
  color: #ffffff;
  border-radius: 0.6rem;
  margin: 0.4rem auto;
  font-size: 0.32rem;
  background-color: #ECBB34;
}
.c-reg-panel > li:last-child {
  margin-bottom: 0;
}
.c-reg-panel > li {
  display: flex;
  border-bottom: solid 0.01rem #D3D9DE;
  margin-bottom: 0.25rem;
}
.c-reg-panel > li > .c-reg-panel-leftbox {
  flex: 1;
}
.c-reg-panel > li > .c-reg-panel-leftbox label {
  color: #cacaca;
  display: block;
  text-align: left;
  font-size: 0.28rem;
  margin-bottom: 0.1rem;
}
.c-reg-panel > li > .c-reg-panel-leftbox input {
  display: block;
  border: none;
  background: transparent;
  outline: none;
  height: 0.88rem;
  line-height: 0.88rem;
  width: 100%;
  font-size: 0.28rem;
  font-weight: 500;
  color: #02172E;
  position: relative;
  z-index: 1;
}
.c-reg-panel > li > .c-reg-panel-leftbox input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #999999;
}
.c-reg-panel > li > .c-reg-panel-rightbox {
  line-height: 0.8rem;
  min-width: 0.4rem;
}
.c-reg-panel > li > .c-reg-panel-rightbox img {
  width: 0.4rem;
  height: 0.4rem;
}
.c-reg-panel > li > .c-reg-panel-rightbox .active {
  border: none;
  height: 0.6rem;
  padding: 0 0.26rem;
  border-radius: 0.8rem;
  color: #ffffff;
  font-size: 0.24rem;
  line-height: 0.6rem;
  background-color: #ecbb34;
}
.c-reg-panel > li > .c-reg-panel-rightbox .active span {
  color: #cacaca;
}
.c-reg-panel > li > .c-reg-panel-rightbox .c-reg-code {
  border: none;
  height: 0.6rem;
  padding: 0 0.26rem;
  border-radius: 0.8rem;
  color: #b1a9a9;
  font-size: 0.24rem;
  line-height: 0.6rem;
  background-color: #e0dcdc;
}
.c-reg-panel > li > .c-reg-panel-rightbox .c-reg-code span {
  color: #cacaca;
}
.c-reg-toreg {
  width: 100%;
  margin: 0.4rem auto 0;
}
.c-reg-toreg span {
  color: #000000;
  font-size: 16px;
}
.c-nabox {
  width: 5.6rem;
  margin: 1rem auto 0;
  text-align: right;
}
.c-nabox span {
  color: #cacaca;
  font-size: 14px;
}
.langTaggle {
  position: absolute;
  right: 0.1rem;
  color: #c3bdbd;
  width: 1.8rem;
  box-sizing: border-box;
  height: 0.46rem;
  display: flex;
  margin-top: 1.2rem;
  border: 0px;
}
.langTaggle .lang-icon {
  width: 0.58rem;
  height: 0.48rem;
  margin-right: 2px;
}
.langTaggle /deep/ .van-field {
  border: 1px rgba(0, 0, 0, 0.7) solid;
  width: 0.8rem;
  padding: 0;
  height: 0.46rem;
  font-size: 0.26rem;
}
.langTaggle /deep/ .van-field .van-field__control {
  line-height: 0.46rem;
  text-align: center;
}
.flag-list-box {
  background: #ffffff;
}
/* ----20210409--- */
.posRelat {
  position: relative;
}
.getCodeBtn {
  position: absolute;
  z-index: 2;
  right: 0;
  bottom: 0.15rem;
}
.getCodeBtn button {
  color: #EFBA0D;
  font-size: 0.24rem;
  height: 0.52rem;
  border: none;
  background: none;
}
body {
  font-family: 'PingFangSC-Regular', 'PingFang SC', "微软雅黑", "Microsoft Yahei";
}
.registBox {
  min-height: 100%;
  font-family: PingFangSC-Regular;
  background-color: #FFFFFF;
  /* .head {
		display: flex;
		margin: 0 0.72rem 0 0.72rem;
		padding-top: 0.28rem;
		align-items: center;
		justify-content: space-between; */
  /* } */
}
.registBox .langBtn .icon {
  width: 0.36rem;
  height: 0.3352rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.registBox .langBtn .icon img {
  display: block;
  width: 100%;
}
.registBox .zhuce .txt {
  text-align: center;
  line-height: 0.51rem;
  color: #EFBA0D;
  font-size: 0.32rem;
  font-weight: 600;
}
.registBox .registBody {
  padding: 0.95rem 0.67rem 0.4rem 0.71rem;
}
.registBox .registBody .headIcon {
  width: 3.15rem;
  height: 3.15rem;
  margin: auto;
}
.registBox .registBody .headIcon img {
  width: 100%;
  height: 100%;
  display: block;
  margin: 0 auto;
}
.registBox .registBody .agreelaw {
  font-size: 0.24rem;
  color: #c3c5c8;
  padding-top: 0.4rem;
}
.registBox .registBody .agreelaw .mainColor1 {
  color: #EFBA0D;
}
.registBox .registBody .tologin {
  padding-top: 0.2rem;
  font-size: 0.24rem;
  text-align: center;
  color: #999;
  padding-bottom: 0.3rem;
}
.registBox .registerPage {
  color: #FFFFFF;
  padding: 0.2rem 0.2rem 0.4rem;
}
.registBox .titleBtnStyle {
  background: #FC9216;
  border: 0px;
  border-radius: 6px;
  width: 1.6rem;
  float: right;
}
.login-dex {
  padding-top: 1.44rem;
}
/* 我同意字体在有的分辨率下显示不全的问题 */
