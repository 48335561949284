.btn1,
.btn2 {
  color: #000;
  border: 0;
  font-size: 14px;
  background: none;
  padding: 0;
  margin-right: 0.36rem;
}
/*.yaoQingStyle {*/
/* position: absolute;
            bottom: 1.2rem;
            left: 0rem auto; */
/*margin-top: 11rem;*/
/*}*/
.titlePStyle {
  /* margin-bottom: 0.2rem; */
  margin-top: 0.2rem;
  /* padding-top: 0.1rem; */
  margin-right: 7rem;
  transform: rotate(180deg);
}
.qrcode {
  width: 0.3rem;
}
.fengXiangCode {
  position: absolute;
  bottom: 6.5rem;
  left: 3.2rem;
}
.spanStyle {
  margin-right: 7rem;
}
.op_container {
  flex: 1;
  text-align: left;
}
.container {
  background-position: center top;
  background-size: 100% 100%;
  font-family: PingFangSC-Regular;
  height: 100%;
}
.container .content {
  height: calc(100% - 0.88rem);
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
}
.container .shareFooter {
  position: absolute;
  bottom: 1.35rem;
  left: 0;
  right: 0;
  margin: 0 0.4rem;
  display: flex;
  align-items: center;
}
.container .shareFooter .code {
  width: 1.29rem;
  height: 1.29rem;
  margin: 0 auto;
}
.container .shareFooter .codeImgStyle {
  width: 1.42rem;
  height: 1.42rem;
  border: 3px solid #FFFFFF;
}
.container .shareFooter .codeImgStyle img {
  width: 100%;
  height: 100%;
}
.container .shareFooter .yaoQingStyle {
  color: #EFBA0D;
  font-size: 16px;
}
.container .shareFooter .yaoQingStyle span {
  font-family: PingFangSC-Medium;
  font-weight: bold;
}
.container .shareFooter .yaoQingStyle:last-child {
  margin-top: 0.52rem;
}
.container .shareFooter .rightBtn {
  margin-right: 10px;
}
.footerBtn {
  color: #ffffff;
  font-size: 0.58rem;
  width: 4.24rem;
  height: 1.54rem;
  padding-bottom: 0.14rem;
  border-radius: 0.77rem;
  background: #ff4b04 url(../../assets/imgs/share/VIP.png);
  background-size: 100% 100%;
  margin: 0 auto;
}
.footerBtn span {
  font-weight: bold;
}
.layoutBox {
  padding: 0.1rem 0.3rem 0.5rem;
}
.layoutBox .shareAds {
  margin-bottom: 0.1rem;
}
.layoutBox .shareAds img {
  display: block;
  width: 100%;
}
.layoutBox .shareSteps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.25rem;
  background: #FFFFFF;
  margin-bottom: 0.1rem;
}
.layoutBox .shareSteps .item {
  font-size: 0.28rem;
  text-align: center;
}
.layoutBox .shareSteps .item .icon {
  margin-bottom: 0.1rem;
}
.layoutBox .shareSteps .item .icon img {
  width: 0.64rem;
  height: 0.64rem;
  display: block;
  margin: 0 auto;
}
.layoutBox .shareSteps .item p {
  width: 4em;
}
.layoutBox .shareSteps .item p.mtext {
  width: 6em;
}
.layoutBox .shareSteps .split {
  width: 0.32rem;
  height: 0.3rem;
  background: url(../../assets/imgs/share/arr.png) no-repeat center center;
  background-size: 100% 100%;
}
.layoutBox .shareInfo {
  background: #FFFFFF;
  border-radius: 0.12rem;
  margin-bottom: 0.1rem;
}
.layoutBox .shareInfo .top {
  padding: 0.25rem;
  border-bottom: 1px solid #EDEDED;
}
.layoutBox .shareInfo .bottom {
  padding: 0.25rem;
  display: flex;
  justify-content: space-between;
}
.layoutBox .shareInfo .bottom .btn {
  color: white;
  font-size: 0.32rem;
  text-align: center;
  width: 3.25rem;
  height: 0.88rem;
  line-height: 0.88rem;
  background: #93C4FC;
  border-radius: 0.12rem;
}
.layoutBox .shareInfo .bottom .btn.btnR {
  background: #E0C3FC;
  position: relative;
}
.layoutBox .shareInfo .bottom .btn.btnR::before {
  content: '';
  position: absolute;
  left: -0.15rem;
  width: 0.3rem;
  height: 100%;
  background: #FFFFFF;
  transform: skewX(-15deg);
  transform-origin: 50% 50%;
}
.layoutBox .shareInfo .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.1rem;
}
.layoutBox .shareInfo .item:last-child {
  margin-bottom: 0;
}
.layoutBox .shareInfo .item .inp {
  height: 0.7rem;
  line-height: 0.7rem;
  padding: 0 0.1rem 0 0.35rem;
  background: #F6F7F9;
  border-radius: 0.34rem;
  display: flex;
  justify-content: space-between;
}
.layoutBox .shareInfo .item .inp .link {
  width: 3rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.layoutBox .shareInfo .item .copy {
  width: 0.7rem;
  height: 0.7rem;
  background: url(../../assets/imgs/share/invitation_icon_file.png) no-repeat center center;
  background-size: 0.4rem 0.4rem;
  border: 0;
}
.layoutBox .shareReward {
  padding: 0.25rem;
  background: #FFFFFF;
  border-radius: 0.12rem;
}
.layoutBox .shareReward .caption {
  height: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.15rem;
}
.layoutBox .shareReward .caption h3 {
  font-size: 0.32rem;
}
.posterLayout {
  position: fixed;
  z-index: 98;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(4, 4, 15, 0.4);
}
.posterCode {
  position: fixed;
  z-index: 99;
  left: 0.3rem;
  right: 0.3rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.posterCode .poster {
  position: relative;
}
.posterCode .poster img {
  display: block;
  width: 100%;
}
.posterCode .poster .code {
  position: absolute;
  left: 50%;
  top: 2.9rem;
  width: 1.8rem;
  height: 1.8rem;
  margin-left: -0.9rem;
}
.posterCode .close {
  width: 0.8rem;
  height: 0.8rem;
  background: url(../../assets/imgs/share/icon_shut.png) no-repeat center center;
  background-size: 0.6rem 0.6rem;
  margin: 0.5rem auto 0;
}
