.container,
.content {
  height: 100%;
}
.layoutBox {
  font-family: PingFangSC-Regular;
  min-height: calc(100% - 0.2rem);
  padding: 0 10px;
  margin-top: 0.2rem;
  border-top-left-radius: 0.32rem;
  border-top-right-radius: 0.32rem;
  padding-top: 0.2rem;
}
