















































































































































































































.body1StyleInputStyle {
	border: 0px;
	font-size: 0.28rem;
	width: 6.7rem;
	line-height: 0.8rem;
}
.pc_in {
	width: 100%;
	position: relative;
	overflow: hidden;
	margin-top: 0.22rem;
}

.pc_in .divbox {
	display: flex;
	width: 100%;
	// justify-content: space-between;
	height: 1rem;
	overflow: hidden;
}

.pc_in .divbox span {
	// flex: 1;
	width: 1.4rem;
	height: 100%;
	// display: block;
	// float: left;
	// margin: 0 0.2rem;
	border-radius: .04rem;
	border: 1px solid #E5E5E5;
	&:first-child {
		border-top-left-radius: 0.16rem;
		border-bottom-left-radius: 0.16rem;
	}
	&:last-child {
		border-top-right-radius: 0.16rem;
		border-bottom-right-radius: 0.16rem;
	}
	// margin: 0 .08rem;
	// &:first-child {
	// 	margin-left: 0;
	// }
	// &:last-child {
	// 	margin-right: 0;
	// }
}

.pc_in .divbox span.bl {
	border-color: #FAD538;
	
	color: #FAD538;
	line-height: 1rem;
	font-size: 0.64rem;
	text-indent: 0.3rem;
}
.pc_in p {
	position: absolute;
	// width: 100%;
	width: 100%;
	height: 1rem;
	line-height: 1rem;
	top: 0;
	left: 0;
	background: none;
	overflow: hidden;
	// padding: 0 .3rem;
}

.pc_in p input {
	color: #FAD538;
	height: 1rem;
	line-height: 1rem;
	letter-spacing: 0.75rem;
	background: none;
	// text-indent: 0.3rem;
	font-size: 0.64rem;
	display: block;
	width: 100%;
	
	text-indent: -1em;
	opacity: 0;
}

// ----------20200411[start]-----------
.layoutBox {
	min-height: calc(100vh - 54px);
	margin-top: 0.2rem;
	border-top-left-radius: 0.32rem;
	border-top-right-radius: 0.32rem;
	font-family: PingFangSC-Regular;
}
.comBtnStyle {
	margin: 0 0.2rem;
	color: #333333;
	font-size: 0.32rem;
	text-align: center;
	height: 0.9rem;
	line-height: 0.9rem;
	background: #FAD538;
	border-radius: 0.16rem;
}
.securityCheck {
	padding: 0.3rem 0;
	margin: 0 0.4rem;
	font-size: .28rem;
	.top {
		display: flex;
		align-items: center;
		margin-bottom: 0.4rem;
		font-size: 0.28rem;
		color: #333333;
		.caption {
			// font-weight: bold;
			// line-height: .45rem;
		}
		.phone {
			margin-left: 0.2rem;
		}
	}
	.verifyCode {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin-bottom: 0.64rem;
		border-bottom: 0.5px solid rgba(211, 217, 222, 0.6);
		.inp {
			color: #999999;
			font-size: .24rem;
			display: block;
			width: 100%;
			flex: 1;
			// height: .6rem;
			padding: 10px 0;
			border: 0;
		}
		.btn {
			padding: 0.2rem 0;
			font-size: 0.24rem;
			// border: 1px solid #90C4FF;
			margin-left: .25rem;
		}
	}
	.c_title {
		color: #333333;
		font-size: 0.28rem;
		font-weight: bold;
	}
}
// ----------20200411[end]-----------

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;