.moreImg {
  width: 0.6rem;
  /* height: 0.2rem; */
}
.sspanStyleCol22 {
  margin-left: 0.2rem;
}
.vanRowBoderStyle {
  border-bottom: 1px solid #ededed;
  margin: 0 0.3rem;
  padding: 0.15rem 0 0.2rem;
}
.vanCol4PStyle {
  font-size: 0.28rem;
  text-align: right;
  margin-top: 0.25rem;
}
.vanCol4PStyle span {
  font-family: Regular;
}
.bottomDivRowColStylePS {
  font-size: 0.28rem;
  line-height: 0.4rem;
  margin-top: 0.08rem;
  display: flex;
  color: #333333;
}
.bottomDivRowColStyleP2 {
  font-size: 0.24rem;
  line-height: 0.35rem;
}
.fontColorStyle {
  color: #333;
}
.zhongjiandeDiv {
  height: 0.8rem;
  font-size: 0.32rem;
  padding: 0 0.3rem;
  padding-top: 0.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.zhongjiandeDiv .head_left {
  font-size: 0.32rem;
  color: #333333;
  display: flex;
  align-items: center;
  font-weight: bold;
}
.zhongjiandeDiv .head_left .kuai {
  width: 0.1rem;
  height: 0.26rem;
  background-color: #f8d33a;
  margin-right: 0.2rem;
  margin-top: 0.05rem;
}
.zhongjiandeDiv .head_right {
  display: flex;
  align-items: center;
}
.zhongjiandeDiv .head_right .txt {
  margin-right: 0.1rem;
  color: #999999;
  font-size: 0.24rem;
}
.zhongjiandeDiv .head_right .icon_arrR {
  width: 0.24rem !important;
  height: 0.24rem !important;
  border-top: 0.04rem solid #999;
  border-right: 0.04rem solid #999;
}
.body1Style {
  background-color: #ffffff;
  padding: 0.28rem 0.4rem 0.4rem 0.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.body1Style .spanFlex6 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.body1Style .spanFlex6 .img {
  width: 0.6rem;
  height: 0.6rem;
}
.body1Style .spanFlex6 .img img {
  width: 100%;
  height: 100%;
}
.body1Style .spanFlex6 .txt {
  margin-top: 0.03rem;
  text-align: center;
  font-family: PingFang SC;
  font-weight: 500;
  font-size: 0.28rem;
  color: #333333;
}
.new_bgMask {
  background-color: #ffffff;
}
.new_bgMask .bgMask {
  margin-top: -0.5rem;
  height: 0.69rem;
  width: 100%;
  background: url(../../assets/imgs/new_color/mask.png) no-repeat center top;
  background-size: 100% 0.69rem;
  position: relative;
  z-index: 100;
}
.whole {
  /* background: #ffffff; */
  font-family: PingFangSC-Regular;
}
.content {
  position: relative;
  padding-top: 1.08rem;
  padding-top: calc(env(safe-area-inset-top) + 1.08rem);
  left: 0;
  width: 100%;
}
.content::before {
  content: '';
  height: 3.95rem;
  height: calc(env(safe-area-inset-top) + 3.95rem);
  width: 100%;
  background: url(../../assets/imgs/new_color/qianbao_bg.png) no-repeat center top;
  background-size: 100% 100%;
  display: inline-block;
  position: absolute;
  top: 0;
}
.accountInfo {
  color: white;
  font-size: 0.24rem;
  margin: 0 0.4rem;
  height: 2.82rem;
  border-radius: 0.12rem;
  position: relative;
  z-index: 10;
  background-image: url(../../assets/imgs/new_color/q_bg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 99;
}
.accountInfo .head_txt {
  padding: 0.4rem 0.4rem 0.33rem 0.4rem;
  color: #facdb6;
  font-size: 0.4rem;
  font-family: pingfangSC-Medium;
}
.accountInfo .qianbao {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.accountInfo .qianbao .item {
  width: 33.3%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}
.accountInfo .qianbao .item:first-child {
  border-right: 0.02rem solid rgba(250, 205, 182, 0.33);
}
.accountInfo .qianbao .item:last-child {
  border-left: 0.02rem solid rgba(250, 205, 182, 0.33);
}
.accountInfo .qianbao .item .title {
  color: #facdb6;
  font-size: 0.24rem;
  font-family: PingFang SC;
}
.accountInfo .qianbao .item .yen {
  color: #facdb6;
  font-size: 0.28rem;
  font-family: PingFang SC;
}
.leixin {
  width: 2.3rem;
}
.mainColor1 {
  color: #32bf88 !important;
}
.mainColor3 {
  color: #f5465d !important;
}
.container {
  margin-top: 0.2rem;
  border-top-left-radius: 0.32rem;
  border-top-right-radius: 0.32rem;
}
