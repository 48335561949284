.commonRouter {
  padding: 0.4rem 0.4rem 0.3rem 0.4rem;
  background-color: #FFFFFF;
  display: flex;
  justify-content: space-between;
}
.commonRouter li {
  color: #EFBA0D;
  font-size: 0.32rem;
  width: 3.2rem;
  height: 0.68rem;
  border: 1px solid #EFBA0D;
  border-radius: 0.16rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
.commonRouter li.active {
  color: #FFFFFF;
  background: #FAD538;
  border: none;
}
.layoutBox {
  padding: 0 0.2rem;
  min-height: calc(100vh - 2.1rem);
  position: relative;
}
.commonTabtit {
  position: relative;
  padding: 0 0.4rem;
  color: #6F7A8B;
  background-color: #FFFFFF;
  font-size: 0.28rem;
  height: 0.66rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.2rem;
  border-bottom: 0.05rem solid #FFFFFF;
}
.commonTabtit .type {
  display: flex;
  align-items: center;
}
.commonTabtit .type li {
  line-height: 0.66rem;
  margin-right: 0.5rem;
}
.commonTabtit .type li.active {
  color: #333333;
  font-weight: bold;
  border-bottom: 0.05rem solid #EFBA0D;
}
.commonTabtit .img img {
  width: 0.38rem;
  height: 0.36rem;
}
.commonTabtit .exchange {
  height: 0.8rem;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
.commonTabtit .exchange .icon_arr_up {
  width: 0.1rem;
  height: 0.1rem;
  border-right: 2px solid #666666;
  border-bottom: 2px solid #666666;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  margin-left: 0.15rem;
  display: inline-block;
}
.commonTabtit .tan {
  z-index: 99;
  position: absolute;
  right: 0;
  top: 0.59rem;
  background-color: #FFFFFF;
  width: 100%;
  height: 6.4rem;
}
.commonTabtit .tan .content {
  margin: 0.3rem 0.4rem;
}
.commonTabtit .tan .content .head_1 {
  color: #333333;
  font-size: 0.28rem;
  margin-bottom: 0.16rem;
}
.commonTabtit .tan .content .exe_list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.32rem;
}
.commonTabtit .tan .content .exe_list .item {
  font-weight: bold;
  padding: 0.06rem 0.18rem;
  background-color: #F9FAFB;
  color: #999999;
  text-align: center;
  border-radius: 0.04rem;
}
.commonTabtit .tan .content .exe_list .active {
  background-color: #E7ECEF;
  color: #333333;
  position: relative;
}
.commonTabtit .tan .content .exe_list1 {
  display: flex;
  align-items: center;
  margin-bottom: 0.32rem;
}
.commonTabtit .tan .content .exe_list1 .item {
  font-weight: bold;
  padding: 0.06rem 0.34rem;
  background-color: #F9FAFB;
  color: #999999;
  text-align: center;
  margin-left: 0.5rem;
  border-radius: 0.04rem;
}
.commonTabtit .tan .content .exe_list1 .item:first-child {
  margin-left: 0;
}
.commonTabtit .tan .content .exe_list1 .active {
  background-color: #E7ECEF;
  color: #333333;
  position: relative;
}
.commonTabtit .tan .content .head_2 {
  color: #373737;
  font-size: 0.32rem;
  margin-bottom: 0.38rem;
}
.commonTabtit .tan .content .dui_list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.48rem;
}
.commonTabtit .tan .content .dui_list .input input {
  border: none;
  text-align: center;
  font-size: 0.24rem;
  transform: scale(0.91);
  color: #999999;
  padding: 0.2rem 0;
  background-color: #F9FAFB;
  padding: 0.19rem 0.255rem;
  margin-left: -0.12rem;
  border-radius: 0.08rem;
}
.commonTabtit .tan .content .dui_list .fenge {
  width: 0.04rem;
  height: 0.51rem;
  background-color: #EFBA0D;
}
.commonTabtit .tan .content .button {
  display: flex;
  justify-content: space-between;
}
.commonTabtit .tan .content .button .button_1 {
  background-color: #E7ECEF;
  color: #333333;
  font-size: 0.32rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.2rem;
  height: 0.96rem;
  border-radius: 0.16rem;
}
.commonTabtit .tan .content .button .button_2 {
  background-color: #FAD538;
  color: #333333;
  font-size: 0.32rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.2rem;
  height: 0.96rem;
  border-radius: 0.16rem;
}
.zhezhao {
  top: 22vh;
  top: calc(env(safe-area-inset-top) + 22vh);
}
